import { useState } from "react";
import { Dialog, Popover } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import "@fontsource/lora";

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleLinkClick = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="bg-white">
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link
            to="/"
            className="flex items-center space-x-3"
            onClick={handleLinkClick}
          >
            <img
              className="h-8 w-auto"
              src="/yaemlogo2.png"
              alt="Calvary Logo"
            />
            <span className="font-semibold font-lora text-2xl">
              Calvary YAEM
            </span>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-16 pt-1">
          <Link
            to="/"
            className="text-md font-normal text-lg font-lora leading-6 text-gray-900"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-md font-normal text-lg font-lora leading-6 text-gray-900"
          >
            About
          </Link>
          <Link
            to="/service"
            className="text-md font-normal text-lg font-lora leading-6 text-gray-900"
          >
            Service
          </Link>
          <Link
            to="/events"
            className="text-md font-normal text-lg font-lora leading-6 text-gray-900"
          >
            Events
          </Link>
        </Popover.Group>
      </nav>
      <Dialog
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link
              to="/"
              className="flex items-center space-x-3"
              onClick={handleLinkClick}
            >
              <img
                className="h-8 w-auto"
                src="/yaemlogo2.png"
                alt="YAEM Logo"
              />
              <span className="font-semibold font-lora text-2xl">
                Calvary YAEM
              </span>
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-lora font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleLinkClick}
                >
                  Home
                </Link>
                <Link
                  to="/about"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-lora font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleLinkClick}
                >
                  About
                </Link>

                <Link
                  to="/service"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-lora font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleLinkClick}
                >
                  Service
                </Link>

                <Link
                  to="/events"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-lora font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  onClick={handleLinkClick}
                >
                  Events
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
